import React from "react"
import Layout from "../components/Layout";

const ThankYouPage = () => {
  return (
    <Layout>
      <h1 className="text-center">Thank You for Your Request</h1>
      <p className="text-center">Your Subscription Request has been successfully sent. We will send an email confirming your subscription within 48 hours.</p>
    </Layout>
  )
};

export default ThankYouPage
